import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../controls/button";
import CheckBox from "../controls/check-box";
import Input from "../controls/input";
import InputArea from "../controls/input-area";
import Link from "../controls/link";

let captchaValid = true;

interface IContactFormProps {
  className?: String;
  style?: any;
  defaultSubject?: string;
  onChange?: OnFormChange;
}

export type Action =
  | { type: "NAME"; value: string }
  | { type: "EMAIL"; value: string }
  | { type: "OBS"; value: string }
  | { type: "SUBJECT"; value: string }
  | { type: "MOBILE"; value: string };

type OnFormChange = (n: Action) => any;

const ContactForm: React.FC<IContactFormProps> = ({
  className = "",
  style,
  defaultSubject = "",
  onChange
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          apiKeys {
            key
            version
            name
          }
        }
      }
    }
  `);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [obs, setObs] = useState("");
  const [subject, setSubject] = useState("");
  const [mobile, setMobile] = useState("");
  const [valid, setValid] = useState(false);
  const [dataProtection, setDataProtection] = useState(false);

  function captchaOnChange(value: any) {
    captchaValid = true;
    isFormValid();
  }

  function captchaOnError() {
    captchaValid = false;
    isFormValid();
  }

  function captchaOnExpired() {
    captchaValid = false;
    isFormValid();
  }

  let emailReq = true,
    phoneReq = true,
    nameReq = true,
    subjectReq = defaultSubject === "",
    obsReq = true;

  function isFormValid() {
    setValid(captchaValid && dataProtection);
  }

  return (
    <form
      style={style}
      className={`${className}`}
      onSubmit={() => {
        alert(`Dados introduzidos não são válidos.`);
      }}
    >
      <div className="flex flex-col max-w-screen-md items-center w-full">
        <p className="font-sans text-secondary-700 font-bold text-3xl text-center border-b-2 border-highlight-500">
          Formulário de Contacto
        </p>
        <Input
          className="w-full lg:px-2"
          required={nameReq}
          id="nome-input"
          value={name}
          label="Nome"
          placeholder="Nome..."
          onChange={item => {
            setName(item.target.value);
            if (onChange)
              onChange({
                type: "NAME",
                value: name
              });
            isFormValid();
          }}
        />
        <Input
          disabled={!subjectReq}
          className="active w-full lg:px-2"
          required={subjectReq}
          id="email-title"
          value={subjectReq === false ? defaultSubject : subject}
          label="Título"
          placeholder="Título..."
          onChange={item => {
            setSubject(item.target.value);
            if (onChange)
              onChange({
                type: "SUBJECT",
                value: subject
              });
            isFormValid();
          }}
        />
        <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row w-full">
          <Input
            className="flex-grow lg:px-2"
            required={emailReq}
            value={email}
            id="email-input"
            label="Email"
            placeholder="Email..."
            onChange={item => {
              setEmail(item.target.value);
              if (onChange)
                onChange({
                  type: "EMAIL",
                  value: email
                });
              isFormValid();
            }}
          />
          <Input
            className="flex-grow lg:px-2"
            required={phoneReq}
            value={mobile}
            id="mobile-input"
            label="Telemóvel"
            placeholder="Telemóvel..."
            onChange={item => {
              setMobile(item.target.value);
              if (onChange)
                onChange({
                  type: "MOBILE",
                  value: mobile
                });
              isFormValid();
            }}
          />
        </div>

        <InputArea
          className="w-full lg:px-2"
          required={obsReq}
          id="obs"
          value={obs}
          label="Observações"
          placeholder="Observações..."
          onChange={(value: string) => {
            setObs(value);
            if (onChange)
              onChange({
                type: "OBS",
                value: obs
              });
            isFormValid();
          }}
        />
      </div>

      {!valid ? (
        <p className="text-red-500 text-xs italic">
          Por favor preencha todos os campos.
        </p>
      ) : null}

      <CheckBox
        className="mt-5"
        id="data-protect-check"
        onChange={(state: boolean) => {
          setDataProtection(state);
          isFormValid();
        }}
      >
        <p className="overflow-visible">
          {"Aceito a partilha de informação para contacto. "}
          <Link
            className="text-blue-700 underline"
            to="https://dre.pt/pesquisa/-/search/123815982/details/maximized"
            openNewTab={true}
            internal={false}
          >
            Lei da proteção de dados
          </Link>
        </p>
      </CheckBox>

      <div className="flex justify-center m-5">
        <ReCAPTCHA
          sitekey={
            (site.siteMetadata.apiKeys as any[]).find(
              x => x.version && x.version === "2"
            ).key
          }
          onChange={captchaOnChange}
          onErrored={captchaOnError}
          onExpired={captchaOnExpired}
        />
      </div>

      {!valid ? (
        <Button
          className="self-center flex-grow-0 bg-highlight-600 hover:bg-highlight-500 text-black rounded focus:outline-none focus:shadow-outline"
          onClick={() => {}}
          ariaLabel="Enviar"
        >
          Enviar
        </Button>
      ) : (
        <a
          className="self-center flex-grow-0 bg-highlight-600 hover:bg-highlight-500 text-black rounded focus:outline-none focus:shadow-outline"
          href={`mailto:${site.siteMetadata.email}?subject=${
            subject == "" ? defaultSubject : subject
          }&body=${name} - tel. ${mobile}%0A${obs}`}
          target="_blank"
        >
          Enviar
        </a>
      )}
    </form>
  );
};

// tslint:disable-next-line: export-name
export default ContactForm;
