import { graphql } from "gatsby";
import GoogleMapReact, { MapOptions } from "google-map-react";
import React from "react";
import ContactForm from "../components/forms/contact-form";
import Head from "../components/layout/head";
import Root from "../components/layout/root";
import Section from "../components/layout/section";
import { MapMarker } from "../components/controls/map-marker";

interface Props {
  data: {
    site: {
      siteMetadata: {
        address: string;
        phone: string;
        email: string;
        contacts: {
          name: string;
          number: string;
          info: string;
        }[];
        map: {
          lat: number;
          lon: number;
          defaultZoom: number;
        };
      };
    };
  };
}

const AboutUs: React.FC<Props> = ({ data }) => {
  const { site } = data;
  const { lat, lon, defaultZoom } = site.siteMetadata.map;
  function createMapOptions(maps: any) {
    return {
      mapTypeControlOptions: {
        style: "default",
        mapTypeIds: ["roadmap", "terrain"]
      },
      styles: [{ stylers: {} }]
    } as MapOptions;
  }

  return (
    <Root>
      <Head title="Details" />
      <div className="container w-full content-center">
        <p className="font-sans text-secondary-600 font-bold text-3xl text-center">
          Stand Carvalho Braga
        </p>
        <div className="p-4">
          <span className="block text-center px-4 py-2 mt-2">
            {site.siteMetadata.address}
          </span>

          <Section container={false}>
            <div className="w-full" style={{ height: 375 }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  //key: "AIzaSyCeIofNRbratxDalQSh5aFL3kXPzrZ_HjI"
                  key: "AIzaSyD1cs7EufTuVm4PIM8FdTQ3NklKOM-WaM4"
                }}
                defaultCenter={{ lat: lat, lng: lon }}
                defaultZoom={defaultZoom}
              >
                <MapMarker lat={lat} lng={lon} />
              </GoogleMapReact>
            </div>
          </Section>

          <div className="flex flex-col justify-center flex-grow items-center">
            <span className="px-4 py-2 mt-2">
              <p className="font-sans text-secondary-600 font-bold text-3xl text-center border-b-2 border-highlight-500">
                Contactos
              </p>
            </span>
            {site.siteMetadata.contacts.map((item: any, index: any) => {
              return (
                <div key={index} className="flex flex-col justify-center">
                  <div className="flex my-1">
                    <div className="mr-2 font-bold">{item.name}:</div>
                    <div className="ml-2">{item.number}</div>
                  </div>
                  <div>{item.info}</div>
                </div>
              );
            })}
            <span className="text-center px-4 py-2">
              {site.siteMetadata.email}
            </span>
          </div>

          <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row">
            <div className="flex flex-col w-full justify-start items-center">
              <p className="font-sans text-secondary-600 font-bold text-3xl text-center border-b-2 border-highlight-500">
                Horário
              </p>
              <table className="border-0 table-row text-md whitespace-no-wrap">
                <thead>
                  <tr>
                    <td>
                      <strong className="text-xs">
                        *Damos primazia ao atendimento por marcação
                      </strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>Segunda:</p>
                    </td>
                    <td>09:00-19:00</td>
                  </tr>
                  <tr>
                    <td>Terça:</td>
                    <td>09:00-19:00</td>
                  </tr>
                  <tr>
                    <td>Quarta:</td>
                    <td>09:00-19:00</td>
                  </tr>
                  <tr>
                    <td>Quinta:</td>
                    <td>09:00-19:00</td>
                  </tr>
                  <tr>
                    <td>Sexta:</td>
                    <td>09:00-19:00</td>
                  </tr>
                  <tr>
                    <td>Sábado:</td>
                    <td>09:00-16:00*</td>
                  </tr>
                  <tr>
                    <td>Domingo:</td>
                    <td>Marcações*</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ContactForm className="content-center w-full" />
          </div>
        </div>
      </div>
    </Root>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        address
        phone
        email
        contacts {
          name
          number
          info
        }
        map {
          lat
          lon
          defaultZoom
        }
      }
    }
  }
`;

export default AboutUs;
